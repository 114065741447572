//import { graphql, useStaticQuery, Link } from "gatsby";
import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import HomeHero from "../components/homeHero";
import { motion } from "framer-motion";
//import { node } from "prop-types";

function Blog({ data }) {
	let blogHeader = data.blogBannerContent.edges[0].node.frontmatter;
	let blogPosts = data.blogPostContent.edges;

	//console.log(data);
	return (
		<Layout>
			<SEO
				keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
				title="Blog"
			/>

			<HomeHero
				headline={blogHeader.blogBannerHeadline}
				copy={blogHeader.blogBannerHeadText}
				phone={blogHeader.blogBannerPhone}
				image={blogHeader.blogBannerImage.childImageSharp.fluid}
			/>

			<div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
				<div className="absolute inset-0">
					<div className="bg-white h-1/3 sm:h-2/3" />
				</div>
				<div className="relative max-w-7xl mx-auto">
					<div className="text-center">
						<motion.div
							style={{ originY: 1 }}
							initial="hidden"
							animate="visible"
							variants={{
								hidden: {
									scale: 0.8,
									opacity: 0,
								},
								visible: {
									scale: 1,
									opacity: 1,
									transition: {
										delay: 1.2,
									},
								},
							}}
						>
							<h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
								Tips on Tree care
							</h2>
						</motion.div>
						<motion.div
							style={{ originY: 1 }}
							initial="hidden"
							animate="visible"
							variants={{
								hidden: {
									scale: 0.8,
									opacity: 0,
								},
								visible: {
									scale: 1,
									opacity: 1,
									transition: {
										delay: 1.4,
									},
								},
							}}
						>
							<p className="mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-500 sm:mt-4">
								As well as other general writings on arboriculture and caring
								for the urban forests of southern Wisconsin.
							</p>
						</motion.div>
					</div>
					<motion.div
						style={{ originY: 1 }}
						initial="hidden"
						animate="visible"
						variants={{
							hidden: {
								scale: 1,
								opacity: 0,
							},
							visible: {
								scale: 1,
								opacity: 1,
								transition: {
									delay: 1.6,
								},
							},
						}}
					>
						<div className="mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
							{blogPosts.map((post, index) => {
								return (
									<div
										key={index}
										className="flex flex-col rounded-lg shadow-lg overflow-hidden"
									>
										<div className="flex-shrink-0">
											{/*
										<img
											className="h-48 w-full object-cover"
											src="https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"
											alt=""
										/>
										*/}
											<Img
												className="h-48 w-full object-cover"
												fluid={
													post.node.frontmatter.image.childImageSharp.fluid
												}
												alt=""
											/>
										</div>
										<div className="flex-1 bg-white p-6 flex flex-col justify-between">
											<div className="flex-1">
												{/*
											<p className="text-sm leading-5 font-medium text-indigo-600">
												<a href="/#" className="hover:underline">
													Blog
												</a>
											</p>
											*/}
												<a
													href={"/posts/" + post.node.frontmatter.slug}
													className="block"
												>
													<h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
														{post.node.frontmatter.title}
													</h3>
													<p className="mt-3 text-base leading-6 text-gray-600 font-light">
														{post.node.excerpt}
													</p>
												</a>
											</div>
											<div className="mt-6 flex items-center">
												<div>
													<p className="text-sm leading-5 font-medium text-gray-900">
														LP Tree Service
													</p>
													<p className="text-sm leading-5 text-gray-500">
														Janesville, WI
													</p>
													<div className="flex text-sm leading-5 text-gray-500">
														<time dateTime="2020-03-16">
															{post.node.frontmatter.date}
														</time>
														<span className="mx-1">·</span>
														<span>{post.node.fields.readingTime.text}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</motion.div>
				</div>
			</div>
		</Layout>
	);
}

export default Blog;

export const BlogQuery = graphql`
	query {
		blogPostContent: allMarkdownRemark(
			filter: { frontmatter: { type: { eq: "post" } } }
			sort: { order: DESC, fields: frontmatter___date }
		) {
			edges {
				node {
					id
					fields {
						readingTime {
							text
						}
					}
					frontmatter {
						date(fromNow: true)
						title
						slug
						image {
							childImageSharp {
								fluid(maxWidth: 200, maxHeight: 200) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
					excerpt(truncate: true)
				}
			}
		}
		blogBannerContent: allMarkdownRemark(
			filter: { frontmatter: { pageName: { eq: "blog" } } }
		) {
			edges {
				node {
					frontmatter {
						blogBannerHeadline
						blogBannerHeadText
						blogBannerPhone
						blogBannerImage {
							id
							childImageSharp {
								fluid(quality: 70, fit: COVER) {
									...GatsbyImageSharpFluid
								}
							}
							absolutePath
						}
					}
					rawMarkdownBody
					id
					html
				}
			}
		}
	}
`;
